import { render, staticRenderFns } from "./graph-hotels-legend.vue?vue&type=template&id=24d00feb&scoped=true&"
import script from "./graph-hotels-legend.vue?vue&type=script&lang=ts&"
export * from "./graph-hotels-legend.vue?vue&type=script&lang=ts&"
import style0 from "./graph-hotels-legend.vue?vue&type=style&index=0&id=24d00feb&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24d00feb",
  null
  
)

export default component.exports