
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';

import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import { KEY } from '@/inversify.keys';
import { SettingsGeneralService } from '@/modules/settings/settings-general.service';
import { UserServiceS } from '@/modules/user/user.service';
import type UserService from '@/modules/user/user.service';

@Component({
    components: {
        CustomSelect,
        StyledSearchbar,
    },
})
export default class CurrencySwitcher extends Vue {
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;
    @inject(UserServiceS) private userService!: UserService;

    query: string = '';

    // NOTE: Adding support v-model for this component
    @Prop()
    private value!: string;

    isLoading = false;

    get currencyItems() {
        const { currencies } = this.userService;

        const currencyList = Object
            .entries(currencies)
            .map(([_, curr]) => ({
                name: `${curr.code}`,
                value: curr.code,
            }))
            .filter(
                item => (this.query
                    ? item.name
                        .toLowerCase()
                        .includes(this.query.toLowerCase())
                    : true),
            );

        currencyList.splice(0, 0, {
            name: this.$tc('filters.byPos'),
            value: null as unknown as string,
        });

        return currencyList;
    }

    get currentCurrency() {
        if (this.isVModelMode()) {
            return this.value;
        }

        return this.settingsGeneralService.displayCurrency;
    }

    set currentCurrency(value: string | null) {
        if (this.isVModelMode()) {
            this.$emit('input', value);
            return;
        }

        this.updateCurrency(value);
    }

    private async updateCurrency(value: string | null) {
        try {
            this.isLoading = true;
            await this.settingsGeneralService.updateDisplayCurrency(value);
        } finally {
            this.isLoading = false;
        }
    }

    updateQuery(newQuery: string) {
        this.query = newQuery;
    }

    private isVModelMode() {
        const { model } = this.$vnode.data as { [k: string]: any; };

        if (model) return true;

        return false;
    }
}
